
import Vue from 'vue'
import { Prop, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import * as rules from '../../../rules/rules'

@Component({
  components: {}
})
export default class IOIdentityForm extends Vue {
  @Prop({ required: true }) dsp: string

  identity = {
    insertionOrderId: '',
    advertiserExternalId: '',
    memberExternalId: ''
  }

  errorIoAlreadyExists = false

  rules = rules

  formState = true

  close () {
    this.$emit('close')
  }

  next () {
    this.$emit('next', this.identity)
  }

  get labelIO () {
    return 'Campaign ID'
  }

  get labelAdvertiser () {
    return 'Ad account id'
  }

  get labelMember () {
    return 'Business manager id'
  }

  @Watch('identity', { deep: true })
  async onChangeIdentity () {
    if (!this.identity.insertionOrderId) {
      return
    }
    const data = {
      campaign_id: String(this.identity.insertionOrderId),
      dsp: this.dsp
    }
    const response = await this.$apiCaller.getInstructions(data)

    if (this.$apiCaller.isResponseError(response)) {
      this.$store.commit('setErrorMessageWithResponse', response)
    } else {
      this.errorIoAlreadyExists = response.data && response.data.length > 0
    }
  }
}
